.card{
    display: flex;
    justify-content: center;
}


.html{
    background: linear-gradient(to bottom right, rgb(252, 115, 3), black);
    color: white;
}
.html:hover{
    transform: scale(105%);
    transition-duration: 500ms;
    
}


.css{
    background: linear-gradient(to bottom right, rgb(0, 132, 255), black);
    color: white;
}
.css:hover{
    transform: scale(105%);
    transition-duration: 500ms;
    
}

.javascript{
    background: linear-gradient(to bottom right, yellow, black);
    color: white;
}
.javascript:hover{
    transform: scale(105%);
    transition-duration: 500ms;
    
}

@media screen and (max-width:900px) {
    .card{
        flex-direction: column;
        align-items: center;
    }
}